import React, { useEffect, useState } from "react"; 
 import { getNews } from "../Service/Api";

const News = () => {
    const [data, setData] = useState([]);

     useEffect(() => {
       const getData = async () => {
         try {
           const datas = await getNews();
           setData(datas);
         } catch (error) {
           console.error("Failed to fetch news:", error);
           setData([]); // Set to empty to ensure the empty state is handled
         }
       };
       getData();
     }, []);

    return (
        <>
            <marquee scrollamount="2" scrolldelay="30" direction="up" align="left" onmouseover="stop();" onmouseout="start();">
                 {data.length > 0 ? (
                    data.map((item, index) => (  
                        <div className='newsarea' key={index}>
                            <div>
                            <p><b>{item.title}</b></p>
                            <p className='newshead'>{item.description}</p>
                            </div>
                                  {item.attachments && item.attachments[0] && (  
                                    <a href={`https://webapi.entab.info/api/image/${item?.attachments}`} target='_blank'>
                                    <div class="button padding arrow"> <div class="img mar-0"> <img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/newsattachment.png" /></div></div>
                                    </a>
                                   )}  
                            
                        </div>
                       
                        
                      ))
                ) : (
                    <div className='newsarea'>
                            <div>
                            <p><b>News Title</b></p>
                            <p className='newshead'>Stay Tuned For More  Updated</p>
                            </div>
                                 
                                    <a href="#" target='_blank'>
                                    <div class="button padding arrow"> <div class="img mar-0"> <img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/newsattachment.png" /></div></div>
                                    </a>
                                  
                            
                        </div>
                )}  
            </marquee>
        </>
    );
};

export default News;
