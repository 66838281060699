import React, {useEffect, useRef} from 'react'; 
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const Events = () => {
  const vidRef=useRef();

useEffect(() => { vidRef.current.play(); },[]);
    var settings = {
        loop: true,
        margin: 0,
        responsiveClass: true,
        autoplay: true,
        nav: true,
        dots: true, smartSpeed: 1500, // transition speed
        fluidSpeed: 2000, 
        items: 1,
        responsive: {
            0: {
                items: 1,
            },
            567: {
                items: 1,
            },
            768: {
                items: 1,
            },
            992: {
                items: 1,
            },
            1200: {
                items: 1,
            },
            1600: {
                items: 1,
            }
        }
    };

    return (
        <OwlCarousel className='owl-theme main-slider' loop margin={10} {...settings}>
          
           
            <div className="item">
              <video src="/Images/video_slider.mp4" className='video_slider'  ref={ vidRef }
  muted
  autoPlay
  loop />
              {/* <img src='https://webapi.entab.info/api/image/TAGOREFBD/public/Images/sld2.jpg'  alt="Tagore Public School, Faridabad" /> */}
              {/* <div class="slide-content">
                <h2>
                Tagore Public School <br/>opening soon -<br /> <span>learn and grow </span> with us! <span></span> 
                </h2>
              </div> */}
            </div>
            {/* <div className="item">
              <img src='https://webapi.entab.info/api/image/TAGOREFBD/public/Images/sld1.jpg'  alt="Tagore Public School, Faridabad" />
              <div class="slide-content">
                <h2>
                creating a new <br/>narrative via <span>education</span><br /> through <span>monuments</span>
                </h2>
              </div>
            </div>  
            <div className="item">
              <img src='https://webapi.entab.info/api/image/TAGOREFBD/public/Images/sld3.jpg'  alt="Tagore Public School, Faridabad" />
              <div class="slide-content">
                <h2>
                creating a new <br/>narrative via <span>education</span><br /> through <span>monuments</span>
                </h2>
              </div>
            </div>  
            <div className="item">
              <img src='https://webapi.entab.info/api/image/TAGOREFBD/public/Images/sld4.jpg'  alt="Tagore Public School, Faridabad"  />
              <div class="slide-content">
                <h2>
                creating a new <br/>narrative via <span>education</span><br /> through <span>monuments</span>
                </h2>
              </div>
            </div>   */}
          
        </OwlCarousel>
    );
};

export default Events;