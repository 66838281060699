import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { getPrincipalMessage } from '../Service/Api';

const PrincipalMessage = () => {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const messages = await getPrincipalMessage();
        setData(messages.filter((item) => item.category === "Principal"));
        setData1(messages.filter((item) => item.category === "Chairman"));
        setData2(messages.filter((item) => item.category === "Director"));
      } catch (err) {
        console.error("Error fetching messages:", err);
        setError("Failed to load messages. Please try again later.");
      }
    };
    fetchData();
  }, []);

  const settings = {
    loop: true,
  margin: 0,
  responsiveClass: true,
  autoplay: true,
  dots: false,
  nav: true,
  items: 1,
  autoHeight: false, 
    responsive: {
      0: { items: 1 },
      567: { items: 1 },
      768: { items: 1 },
      992: { items: 1 },
      1200: { items: 1 },
      1600: { items: 1 },
    },
  };

  const renderMessage = (data, link, defaultImage, defaultName, defaultDesignation, defaultMessage) => (
    data.length > 0 ? data.map((item, index) => (
      <div className="item" key={index}>
        <div className="message-block">
          <div className="message-image animateMe" data-animation="fadeInRight">
            <img src={`https://webapi.entab.info/api/image/${item.attachments[0]}`} alt={item.name} />
          </div>
          <div className="message-content animateMe" data-animation="fadeInLeft">
            <img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/message.gif" className="quotes" alt="Quotes" />
            <div className="princi-Name">
              <h3>{item.name}</h3>
              <p>{item.designation}</p>
            </div>
            <div dangerouslySetInnerHTML={{ __html: item.message }} />
            <Link to={link}>
              <div className="button light padding">
                <span>View More</span> 
                <img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/arrow.png" alt="Arrow" />
              </div>
            </Link>
          </div>
        </div>
      </div>
    )) : (
      <div className="item">
        <div className="message-block">
          <div className="message-image animateMe" data-animation="fadeInRight">
            <img src={defaultImage} alt={defaultName} />
          </div>
          <div className="message-content animateMe" data-animation="fadeInLeft">
            <img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/message.gif" className="quotes" alt="Quotes" />
            <div className="princi-Name">
              <h3>{defaultName}</h3>
              <p>{defaultDesignation}</p>
            </div>
            <p>{defaultMessage}</p>
            <Link to={link}>
              <div className="button light padding">
                <span>View More</span> 
                <img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/arrow.png" alt="Arrow" />
              </div>
            </Link>
          </div>
        </div>
      </div>
    )
  );

  if (error) {
    return <p>{error}</p>; // Display error message if API request fails
  }

  return (
    <OwlCarousel className="owl-theme" {...settings}>
      {renderMessage(data, "/PrincipalMessage", 
        "https://webapi.entab.info/api/image/TAGOREFBD/public/Images/principal.jpg", 
        "Deepika Tarun Sharma", "Principal", "")}

      {renderMessage(data1, "/ChairmanMessage", 
        "https://webapi.entab.info/api/image/TAGOREFBD/public/Images/principal.jpg", 
        "Sudesh Gupta", "Chairman", "Chairman's message content...")}

      {renderMessage(data2, "/director", 
        "https://webapi.entab.info/api/image/TAGOREFBD/public/Images/director.jpg", 
        "Manorma Arora", "Academic Director", "Director's message content...")}
    </OwlCarousel>
  );
};

export default PrincipalMessage;
