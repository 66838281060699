import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import Breadcrumb from "../components/Breadcrumb";

const About = () => {
    return(
        <>
            <Header />
            <Breadcrumb mid="About" breadcrumbName="About Us" />
                <div className="innerSection">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                            <div className="title">
                                    <div>
                                        <h3><span>ABOUT</span> US</h3>
                                    </div>
                                </div>
                                </div>
                            <div className="col-lg-12">
                                <p>Welcome to Tagore Public School, where excellence in education meets unparalleled opportunities for growth and success. With a <b>legacy of over 50 years</b>, under the visionary guidance of our esteemed Academic Director, <b>Mrs. Manorma Arora,</b> Tagore Public Schools in Faridabad and Palwal stands as a beacon of academic brilliance and holistic development.</p>
                                <p>At present, we are proud to nurture the minds of<b> 6500 students</b> across both campuses. Our proven track record in academic achievements speaks volumes, with our students consistently excelling in CBSE examinations and securing admissions to some of the most prestigious colleges and professional courses. Our results are on par with the best institutions in the NCR, with dozens of our students successfully clearing competitive exams such as NEET, IIT, IIM, and CA entrance exams.</p>
                                <p>What sets Tagore Public School apart is our unwavering commitment to fostering a nurturing environment where each student can thrive. Our unique blend of rigorous academics, innovative teaching methods, and a strong emphasis on values and character development ensures that our students are well-prepared for the challenges of the future.</p>
                                <p>Our state-of-the-art facilities, experienced faculty, and comprehensive curriculum provide the perfect setting for students to explore their interests and talents. From advanced science labs and libraries to sports complexes and cultural activities, we offer a holistic education that caters to the diverse needs of our students.</p>
                                <p>Choosing Tagore Public School means choosing a future filled with promise and potential. Limited spaces are available, and we encourage parents to act swiftly to secure a place for their child in our esteemed institution. Join us in our mission to shape the leaders of tomorrow and experience the transformative power of education at Tagore Public School.</p>
                                <p>Feel the pride, hope, and excitement of being part of a community dedicated to excellence. Enroll today and take the first step towards a brighter future for your child.</p>
                                </div>
                            <div className="col-lg-12">
                                <div className="about-img">
                                
                                    <img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/about.jpg" className="img-fluid" alt="Tagore Public School" /> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
            <Copyright />
        </>
    )
}
export default About;