import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import Breadcrumb from "../components/Breadcrumb";

const Mission = () => {
    return(
        <>
            <Header />
            <Breadcrumb mid="About" breadcrumbName="Mission Statement" />
                <div className="innerSection">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                            <div className="title">
                                    <div>
                                        <h3><span>Mission </span> Statement</h3>
                                    </div>
                                </div>
                                </div>
                                <div className="col-lg-6">
                                <div className="">
                                
                                    <img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/mission.jpg" className="img-fluid" alt="Tagore Public School Faridabad"/> 
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p>"At <b>Tagore  Public School, Sector 89,</b> we are committed to creating a dynamic learning environment that nurtures the potential of every individual student. Our mission is to empower students with 21st-century skills, rooted in a strong foundation of Indian cultural heritage and values.</p>
                                <p> With a focus on holistic development, we strive to enable students to become global, responsible citizens who are lifelong learners, effective communicators, and compassionate leaders</p>
                                <p> Through innovative teachinpg methods, collaborative learning, and integration of technology, we aim to create an inclusive space for aspiring students in Faridabad  that fosters creativity, critical thinking, and problem-solving. Our vision for the future is to be recognized as a leading educational institution that prepares students for success in an ever-evolving global landscape, while instilling a sense of pride in their culture, ethnicity and rich heritage."</p>
                                </div>
                            
                        </div>
                    </div>
                </div>
            <Footer />
            <Copyright />
        </>
    )
}
export default Mission;