import { useEffect, useState } from "react";
import React from 'react';
import { useNavigate, Link } from "react-router-dom";
import { getGalleryInner } from "../Service/Api";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import Breadcrumb from "../components/Breadcrumb";

const Gallery = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filterData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [selectedMonth, setSelectedMonth] = useState("All");
  const [monthOptions, setMonthOptions] = useState(["All"]);
  const [yearRanges, setYearRanges] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const Year = `${currentYear}`;
    setSelectedYear(Year);
    console.log("get current Year", Year);
  }, []);

  const convertDateToDDMMYYYY = (isoString) => {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      const galleryData1 = await getGalleryInner();
      const galleryData = galleryData1.filter(item => item.category === 'PhotoGallery');

      // Convert date to DD MM YYYY format
      const formattedGalleryData = galleryData.map(item => ({
        ...item,
        formattedDate: item.date ? convertDateToDDMMYYYY(item.date) : null,
      }));

      setData(formattedGalleryData);

      const yearsSet = new Set();
      const monthsSet = new Set();

      formattedGalleryData.forEach(item => {
        if (item.date) {
          const date = new Date(item.date);
          yearsSet.add(date.getFullYear());
          monthsSet.add(date.getMonth());
        }
      });

      const uniqueYearRanges = Array.from(yearsSet).sort((a, b) => b - a);
      setYearRanges(uniqueYearRanges);

      const monthOptions = Array.from(monthsSet).sort((a, b) => a - b).map(month => monthNames[month]);
      setMonthOptions(["All", ...monthOptions]);
    };

    fetchData();
  }, []);

  useEffect(() => {
    let filteredData = data;

    if (selectedYear !== "All") {
      filteredData = filteredData.filter(item => {
        const itemDate = item.date?.trim();
        if (itemDate) {
          const date = new Date(itemDate);
          const year = date.getFullYear();
          const month = date.getMonth();
          return year === parseInt(selectedYear) && (selectedMonth === "All" || monthNames[month] === selectedMonth);
        }
        return false;
      });
    } else if (selectedMonth !== "All") {
      filteredData = filteredData.filter(item => {
        const itemDate = item.date?.trim();
        if (itemDate) {
          const month = new Date(itemDate).getMonth();
          return monthNames[month] === selectedMonth;
        }
        return false;
      });
    }

    if (searchQuery) {
      filteredData = filteredData.filter(item => item.title.toLowerCase().includes(searchQuery.toLowerCase()));
    }

    // Sort the filtered data by date in descending order
    filteredData.sort((a, b) => new Date(b.date) - new Date(a.date));

    setFilteredData(filteredData);
  }, [selectedYear, selectedMonth, searchQuery, data]);

  const handleModal = id => {
    navigate(`/SubGallery?id=${id}`);
  };

  const emptyArray = [
    {
      description: "“Educating the mind without educating the heart is no education at all.” Sacred Heart has been educating boys and girls since 1990; though much has changed over the years our mission remains the same. Sacred Heart is committed to developing students of high academic achievement, intellectual curiosity, and strong moral character. As a result, year after year Sacred Heart sends our respectful, responsible and compassionate young adults, who are well prepared to tackle the challenges that lay ahead. Since arriving at Sacred Heart in 2016, I have had the opportunity to observe this unique institution up close. I have seen its commitment to academic excellence, its dynamic student body, and incredibly committed faculty and a loving community where the students and families are proud and supportive of one another. As I walk down the hallways of the school every day, I can hear the chatter of eager minds; the shouts of excitement from the victorious athletes, the thumb of dancers feed and the sound of melodious voices harmonizing the perpetual energy, movement and enthusiasm permeate the atmosphere. Ours is a school with difference. We value creativity and innovation and strive to nurture them in our students. Sacred Heartians are groomed to become confident, articulate and enlightened young citizens ready to step into the global society with dignity, honour and integrity. Aristotle once said “Educating the mind without educating the heart is no education at all”. Even as we impart education to match the advancement in technology and globalization, we march our children ahead with Sacred Heart ethos of moral values and principles. We Endeavour constantly to instill these qualities in our children. We pride ourselves to help them grow and develop into sensitive, responsible God loving citizens of the future. May the Sacred Heart of Jesus bless us abundantly in all our endeavourers.",
      attachments: ["director.jpg"]
    }
  ];

  return (
    <>
      <Header />
      <Breadcrumb mid="Gallery" breadcrumbName="Photo Gallery" />

      <div className="innerSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="title">
                <div>
                  <h3><span>Photo   </span> Gallery</h3>
                </div>
                </div>
                </div>
</div>
                <div className="row tabs-dynamic">
                  <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                    <div className="count-val">
                      <p>Total Categories: {filterData.length}</p>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                    <div className="month-selection">
                      <select
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}
                        className="yearSelection">
                        <option value="All">All</option>
                        {yearRanges.map((yr) => (
                          <option key={yr} value={yr}>{yr}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                    <div className="month-selection">
                      <select
                        value={selectedMonth}
                        onChange={(e) => setSelectedMonth(e.target.value)}
                        className="monthSelect">
                        {monthOptions.map((month, index) => (
                          <option key={index} value={month}>{month}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                    <div className="searchBox">
                      <input
                        type="text"
                        id="myInput"
                        name="name"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search Here.."
                      />
                    </div>
                  </div>
                </div>
                <div className="row years year2023 showYear">
                  {filterData.length > 0 ? (
                    filterData.map((item, index) => (
                      <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12 galleryCount" key={index}>
                        <div className="gal_new">
                          <article>
                            <img src={`https://webapi.entab.info/api/image/${item?.attachments[0]}`} alt="Tagore Public School Faridabad" className="img-fluid" />
                            <div className="gal-content">
                              <h4 className="catgry-name">{item.title}</h4>
                              <p>{item.description}</p>
                              <h6 className="date"><i className="bi bi-calendar-week"></i> {item.formattedDate}
                                <span> <a onClick={() => navigate(`/SubGallery?id=${item._id}`)}><i className="bi bi-arrow-right"></i> </a></span>
                              </h6>
                              <div className="total-img"><i className="bi bi-card-image"></i><p>{item?.attachments.length}</p></div>
                            </div>
                          </article>
                        </div>
                      </div>
                    ))
                  ) : (
                    emptyArray.map((data, index) => (
                      <div key={index} className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount">
                        <div className="gal_new">
                          <article>
                            <img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/about.jpg" alt="Tagore Public School Faridabad" className="img-fluid" />
                            <div className="gal-content">
                              <h4 className="catgry-name">Gallery Title</h4>
                              <p>Stay Tuned for Latest Updates</p>
                              <h6 className="date"><i className="bi bi-calendar-week"></i> DD MM YYYY</h6>
                              <div className="total-img"><i className="bi bi-card-image"></i><p>5</p></div>
                            </div>
                          </article>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
            <Footer />
            <Copyright />
          </>
          );
};

          export default Gallery;
