import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Copyright from "../components/Copyright";
import Breadcrumb from "../components/Breadcrumb";

const Vision = () => {
    return(
        <>
            <Header />
            <Breadcrumb mid="About" breadcrumbName="Vision Statement" />
                <div className="innerSection">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                            <div className="title">
                                    <div>
                                        <h3><span>Vision </span> Statement</h3>
                                    </div>
                                </div>
                                </div>
                                <div className="col-lg-6">
                                <div className="">
                                
                                    <img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/vision.jpg" className="img-fluid" alt="Tagore Public School Faridabad" /> 
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p><b>At Tagore Public School, Faridabad</b> our vision is to be a premier educational institution that provides a transformative learning experience to students, enabling them to achieve their full potential. We aim to create a dynamic and inclusive environment that fosters innovation, critical thinking, and creativity, while instilling core values and a strong sense of social responsibility. We envision our school to be a leading center for academic excellence, with a focus on holistic development, empowering students with the knowledge and skills to succeed in a rapidly changing world. Through collaboration, technology integration, and a learner-centric approach, we strive to create lifelong learners who are well-equipped to navigate challenges, and make positive contributions to society. With a commitment to educational excellence, we aim to produce global citizens who will serve as leaders and agents of positive change in a diverse and interconnected world.</p>
                                </div>
                            
                        </div>
                    </div>
                </div>
            <Footer />
            <Copyright />
        </>
    )
}
export default Vision;