import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { getGallery } from '../Service/Api';
import { Link } from  'react-router-dom'
const Gallery = () => {
    const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const categories = [
    { name: "Activities", path: "/Activites", placeholder: "about.jpg" },
    { name: "Competitions", path: "/Competition", placeholder: "about.jpg" },
    { name: "Assemblies", path: "/Assemblies", placeholder: "about.jpg" },
    { name: "Achievements", path: "/Achivement", placeholder: "about.jpg" },
    { name: "Celebrations", path: "/Celebrations", placeholder: "about.jpg" },
    { name: "Sports", path: "/Sports", placeholder: "about.jpg" }
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const galleryData = await getGallery();
        setData(galleryData);
      } catch (error) {
        console.error("Error fetching gallery data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const renderCategory = (category) => {
    const categoryData = data.find(item => item.school === "Senior" && item.category === category.name);

    return (
  
        <div className="item" key={category.name}>
        <div className="gal-img"> 
            <img src={categoryData ? `https://webapi.entab.info/api/image/${categoryData.attachments[0]}`
            : `https://webapi.entab.info/api/image/TAGOREFBD/public/Images/${category.placeholder}`
            }
            className="img-fluid"
            /> 
            <div class="description">
                <Link to={category.path}>
                     <img src="https://webapi.entab.info/api/image/TAGOREFBD/public/Images/zoom.png" /> 
                </Link>
                <h3>{category.name}</h3>  
            </div> 
        </div>
    </div>
    );
  };
  
    var settings = {
        loop: true,
        margin: 10,
        responsiveClass: true,
        autoplay: true,
        dots: false,
        items: 5,
        responsive: {
            0: {
                items: 1,
            },
            567: {
                items: 2,
            },
            768: {
                items: 4,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 4,
            },
            1600: {
                items: 5,
            }
        }
    };
    return (
        <>
            <div className="gallery-box"> 
                <OwlCarousel className='owl-theme' loop margin={10} {...settings}>
                { 
                    categories.map(renderCategory)
                  } 
                </OwlCarousel>
            </div>
        </>
    )
}
export default Gallery