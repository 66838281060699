// import logo from './logo.svg';
import './App.css';
import { BrowserRouter , Route,  Routes } from 'react-router-dom';

// import Home from './Pages/home'
import Home from './Pages/home'; 
import About from './Pages/about'; 
import Vision from './Pages/vision'; 
import Mission from './Pages/mission'; 
import ContactUs from './Pages/contactUs'; 
import SMC from './Pages/smc'; 
import News from './Pages/News'; 
import Events from './Pages/Events';
import Gallery from './Pages/Gallery';
import SubGallery from './Pages/SubGallery';
import VideoGallery from './Pages/VideoGallery';
import PrincipalMessage from './Pages/PrincipalMessage';
import ChairmanMessage from './Pages/ChairmanMessage';
import PageData from './Pages/pageData';
import Director from './Pages/director';
import Cbse from './Pages/Cbse';
import Result from './Pages/Result';
import Tc from './Pages/Tc';
import Achivement from './Pages/Achivement' 
import Assemblies from './Pages/Assemblies'
import Competition from './Pages/Competition'
import Activites from './Pages/Activites'
import Sports from './Pages/Sports' 
import Celebrations from './Pages/Celebrations'
function App() {
  return (
    <div className="App">
      {/* <Home /> */}
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} /> 
          <Route path="/about" element={<About />} /> 
          <Route path="/vision" element={<Vision />} /> 
          <Route path="/mission" element={<Mission />} /> 
          <Route path="/contactUs" element={<ContactUs />} /> 
          <Route path="/smc" element={<SMC />} /> 
          <Route path="/News" element={<News />} /> 
          <Route path="/Events" element={<Events />} /> 
          <Route path="/Gallery" element={<Gallery />} /> 
          <Route path="/SubGallery" element={<SubGallery />} /> 
          <Route path="/VideoGallery" element={<VideoGallery />} /> 
          <Route path="/PrincipalMessage" element={<PrincipalMessage />} /> 
          <Route path="/ChairmanMessage" element={<ChairmanMessage />} /> 
          <Route path="/pageData" element={<PageData />} /> 
          <Route path="/director" element={<Director />} /> 
         <Route path="/Cbse" element={<Cbse />} /> 
         <Route path="/Result" element={<Result />} /> 
          <Route path="/Tc" element={<Tc />} /> 
          <Route path="/Sports" element={<Sports />} />
          <Route path="/Achivement" element={<Achivement />} />
          <Route path="/Assemblies" element={<Assemblies />} />
          <Route path="/Competition" element={<Competition />} />
          <Route path="/Activites" element={<Activites />} />
          <Route path="/Celebrations" element={<Celebrations />} />
        </Routes> 
      </BrowserRouter>
    </div>
  );
}

export default App;